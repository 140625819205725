import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  saved: icon('ic_saved'),
  recent: icon('ic_recent'),
  collection: icon('ic_collection'),
  sampleroom: icon('ic_sampleroom'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('general'),
        items: [
          { title: t('dashboard'), path: paths.dashboard.root, icon: ICONS.dashboard },

          // FEED
          {
            title: t('recently added'),
            path: paths.dashboard.recents.root,
            icon: ICONS.recent,
          },

          {
            title: t('library'),
            path: paths.dashboard.library.root,
            icon: ICONS.saved,
          }
        ],
      },

      {
        subheader: t('sampleroom'),
        items: [

          // PRODUCT
          {
            title: t('my sample room'),
            path: paths.dashboard.sampleroom.root,
            icon: ICONS.sampleroom,
            children: [
              { title: t('create'), path: paths.dashboard.sampleroom.new },
              { title: t('list'), path: paths.dashboard.sampleroom.root },
            ],
          },
        ],
      },


      {
        subheader: t('showcases'),
        items: [

            // COLLECTIONS
            {
              title: t('my collections'),
              path: paths.showroom.collection.root,
              icon: ICONS.collection,
              children: [
                { title: t('create'), path: paths.showroom.collection.new },
                { title: t('list'), path: paths.showroom.collection.root },
              ],
            },
            
          // SHOWCASE
          {
            title: t('my showcase'),
            path: paths.showroom.showcase.root,
            icon: ICONS.kanban,
            children: [
              { title: t('create'), path: paths.showroom.showcase.new },
              { title: t('list'), path: paths.showroom.showcase.root },
            ],
          },

        

        ],
      },


    ],
    [t]
  );

  return data;
}
