import { useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { LinearProgress } from '@mui/material';

// components
import Label from 'src/components/label';

import { useAuthContext } from 'src/auth/hooks';
import { useNavigate } from 'react-router-dom';
// services
import { UseOpenPortal } from 'src/api/product';


// ----------------------------------------------------------------------

export default function NavUpgrade() {
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleStripeRedirect = async () => {
        setLoading(true);
        const portalUrl = await UseOpenPortal();
        setLoading(false);
        console.log("portalUrl.redUrl", portalUrl.redUrl.url.url);
        window.open(portalUrl.redUrl.url.url, "_self")
    };

    const handleClick = () => {
        navigate('/dashboard/pricing');
    };

    return (
        <Stack
            sx={{
                px: 2,
                py: 5,
                textAlign: 'center',
            }}
        >
            <Stack alignItems="center">
                <Box sx={{ position: 'relative' }}>
                    <Avatar src={user?.photoURL} alt={user?.displayName} sx={{ width: 48, height: 48 }} />
                    <Label
                        color="success"
                        variant="filled"
                        sx={{
                            top: -6,
                            px: 0.5,
                            left: 40,
                            height: 20,
                            position: 'absolute',
                            borderBottomLeftRadius: 2,
                        }}
                    >
                        {user.subscription.name}
                    </Label>
                </Box>

                <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
                    <Typography variant="subtitle2" noWrap>
                        {user.displayName}
                    </Typography>

                    <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
                        {user.email}
                    </Typography>
                </Stack>

                {user.subscription.name === "basic" &&
                    <Button variant="contained" onClick={handleClick}>
                        Upgrade Membership
                    </Button>
                }

                {(user.subscription.name === "PRO150" || user.subscription.name === "PRO60" || user.subscription.name === "PRO20") &&
                    <Button variant="contained" onClick={handleStripeRedirect}>
                        Upgrade Membership
                    </Button>
                }

                {loading &&
                    <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360, marginTop:5 }} />
                }
            </Stack>
        </Stack>
    );
}
