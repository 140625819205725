import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { LinearProgress } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useAuthContext } from 'src/auth/hooks';
import Iconify from 'src/components/iconify';
import { UseOpenCheckoutPortal, UseOpenPortal } from 'src/api/product';

// ----------------------------------------------------------------------

export default function PricingCard({ card, sx, ...other }) {
  const { subscription, price, caption, lists, labelAction } = card;
  const { user } = useAuthContext();

  const basic = subscription === 'basic';
  const starter = subscription === 'PRO20';
  const premium = subscription === 'PRO60';
  const enterprise = subscription === 'PRO150';

  const isBasic = user.subscription.name === 'basic';
  const isStarter = user.subscription.name === 'PRO20';
  const isPremium = user.subscription.name === 'PRO60';
  const isEnterprise = user.subscription.name === 'PRO150';

  const [loading, setLoading] = useState(false);


  const handleStripeCheckoutRedirect = async (sub) => {
    setLoading(true);
    const portalUrl = await UseOpenCheckoutPortal(sub);
    setLoading(false);
    console.log("portalUrl.redUrl", portalUrl.redUrl.url.url);
    window.open(portalUrl.redUrl.url.url, "_self")
  };



  console.log("sub type", user.subscription.name)
  const renderSubscription = (
    <Stack spacing={1}>
      <Typography variant="h4" sx={{ textTransform: 'capitalize' }}>
        {subscription}
      </Typography>
      <Typography variant="subtitle2">{caption}</Typography>
    </Stack>
  );

  // eslint-disable-next-line no-nested-ternary
  const renderPrice = basic ? (
    <Typography variant="h3">Free</Typography>
  ) : (
    <Stack direction="row">
      <Typography variant="h3">$</Typography>
      <Typography variant="h3" minWidth="150px">{price}/mo</Typography>
    </Stack>
  );

  const renderList = (
    <Stack spacing={2} minHeight="300px">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box component="span" sx={{ typography: 'overline' }}>
          Features
        </Box>
      </Stack>

      {lists.map((item) => (
        <Stack
          key={item}
          spacing={1}
          direction="row"
          alignItems="center"
          minWidth="270px"
          sx={{
            typography: 'body2',
          }
          }
        >
          <Iconify icon="eva:checkmark-fill" width={16} sx={{ mr: 1 }} />
          {item}
        </Stack>
      ))}
    </Stack>
  );

  return (
    <Stack
      spacing={5}
      sx={{
        p: 5,
        borderRadius: 2,
        boxShadow: (theme) => ({
          xs: theme.customShadows.card,
          md: 'none',
        }),
        ...(starter && {
          borderTopRightRadius: { md: 0 },
          borderBottomRightRadius: { md: 0 },
        }),
        ...((starter || premium || enterprise) && {
          boxShadow: (theme) => ({
            xs: theme.customShadows.card,
            md: `-40px 40px 80px 0px ${alpha(
              theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
              0.16
            )}`,
          }),
        }),
        ...sx,
      }}
      {...other}
    >


      {renderSubscription}

      {renderPrice}

      {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

      {renderList}

      <Button
        fullWidth
        size="large"
        variant="contained"
        disabled={
          (isBasic && subscription === 'basic') ||
          (isStarter && (subscription === 'basic' || subscription === 'PRO20')) ||
          (isPremium && (subscription === 'basic' || subscription === 'PRO20' || subscription === 'PRO60')) ||
          (isEnterprise && (subscription === 'basic' || subscription === 'PRO20' || subscription === 'PRO60' || subscription === 'PRO150'))
        }
        color={starter ? 'primary' : 'inherit'}
        onClick={()=>handleStripeCheckoutRedirect(subscription)}>

        {user.subscription.name === subscription ? 'Current Plan' : 'Upgrade Plan'}
      </Button>

      {loading &&
        <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360, marginTop: 5 }} />
      }

    </Stack>
  );
}

PricingCard.propTypes = {
  card: PropTypes.object,
  sx: PropTypes.object,
};
