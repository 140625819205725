import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import SubscriptionGuard from 'src/auth/guard/subscription-guard';

// ----------------------------------------------------------------------


// SHOWCASE
const ShowcaseDetailsPage = lazy(() => import('src/pages/showroom/showcase/details'));
const ShowcaseListPage = lazy(() => import('src/pages/showroom/showcase/list'));
const ShowcaseCreatePage = lazy(() => import('src/pages/showroom/showcase/new'));
const ShowcaseEditPage = lazy(() => import('src/pages/showroom/showcase/edit'));

// COLLECTION
const CollectionDetailsPage = lazy(() => import('src/pages/showroom/collection/details'));
const CollectionListPage = lazy(() => import('src/pages/showroom/collection/list'));
const CollectionCreatePage = lazy(() => import('src/pages/showroom/collection/new'));
const CollectionEditPage = lazy(() => import('src/pages/showroom/collection/edit'));

export const showroomRoutes = [
  {
    path: 'showroom',
    element: (
      <AuthGuard>
        <DashboardLayout>
        {/* <SubscriptionGuard  hasContent sx={{ py: 10 }}> */}
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
          {/* </SubscriptionGuard> */}
        </DashboardLayout>

      </AuthGuard>
    ),
    children: [
      {
        path: 'showcase',
        children: [
          { element: <ShowcaseListPage />, index: true },
          { path: 'list', element: <ShowcaseListPage /> },
          { path: ':id', element: <ShowcaseDetailsPage /> },
          { path: 'new', element: <ShowcaseCreatePage /> },
          { path: ':id/edit', element: <ShowcaseEditPage /> },
        ],
      },
      {
        path: 'collection',
        children: [
          { element: <CollectionListPage />, index: true },
          { path: 'list', element: <CollectionListPage /> },
          { path: ':id', element: <CollectionDetailsPage /> },
          { path: 'new', element: <CollectionCreatePage /> },
          { path: ':id/edit', element: <CollectionEditPage /> },
        ],
      }
    ],
  },
];
